import React from 'react';
import './index.css';

const InfoCard = ({ stageNumber, stageName, stageNameCH, description }) => (
  <div className="InfoCard">
    <div className="top-dot"></div>
    <div className="bottom-dot"></div>
    <div className="Title">
      <h2>{stageNumber}</h2>
      <div className="StageNameGroup">
          <h5>{stageName}</h5>
          <h5>{stageNameCH}</h5>
      </div>
    </div>
    <p>{description}</p>
  </div>
)

export default InfoCard;
