import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getQRcode } from '../../api/popmusic';

//style & component//
import './index.css';
import Header from '../../components/Header';
import TitleSection from'../../components/TitleSection';
import QRScanner from '../../components/QRScanner';

//image//
import Number02 from '../../assets/images/numbers/02.svg';
import NextIcon from '../../assets/images/icons/next.svg';
import QRImage from '../../assets/images/QRcode.svg';

const PopStory = () => {
  const [scanResult, setScanResult] = useState(null);
  const [fetchError, setFetchError] = useState(false);
  const [fetchResult, setFetchResult] = useState(false);
  const [openQR, setOpenQR] = useState(false);
  const handleResetState = () => {
    setFetchResult(false);
    setOpenQR(false);
    setFetchError(true);
  }
  const handleScan = () => {
    setOpenQR(true);
    setScanResult(null);
    setFetchResult(false);
    setFetchError(false);
  }
  useEffect(() => {
    if(scanResult) {
      getQRcode({ parameter: scanResult })
        .then(response => {
          if (response.isplay) {
            setFetchResult(response.isplay);
            setFetchError(false);
            setOpenQR(false);
          } else {
            handleResetState();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          handleResetState();
        });
    }
    return () => {
      setFetchResult(false);
      setFetchError(false);
    };
	}, [scanResult]);
  return (
    <>
      <div className="PageContainer page">
        <div className={`Canvas-PopStory ${fetchResult && 'scanned'}`}>
          <Header mapStage={2}/>
          <TitleSection 
            image={Number02} alt="02"
            title="The Story of Pop"
            titleCH="好聲共鳴"
          />
          <div className="Content-PopStory">
            <p>掃描唱片牆上的QR code<br></br>收聽觀眾票選Top50</p>
            {openQR ? (
              <QRScanner updateScanResult={result => setScanResult(result)}/>
            ) : (<img src={QRImage} alt="QRcode" />)}
            <div className="ButtonGroup">
              {openQR && <button onClick={() => setOpenQR(false)}>關閉</button>}
              {(!openQR && !fetchError) && <button onClick={handleScan}>{fetchResult ? '再聽一首音樂' : '掃描QR聽音樂'}</button>}
              {fetchError && (
                <>
                  <button onClick={handleScan}>再試一次</button>
                  <div>
                    <small>糟糕，我們的QR掃瞄出了點小問題</small>
                  </div>
                </>
              )}
            </div>
          </div>
          {fetchResult && (
            <div className="Player">
              <iframe title="pop-story" src={scanResult} scrolling="no"></iframe>
            </div>
          )}
          <div className="NextArrow">
            <Link to="/teleport"><img className="next-link-img" src={NextIcon} alt="Go to next stage"/></Link>
          </div>
        </div>
      </div>  
    </>
  )
};

export default PopStory;
