import React from 'react';
import { Link } from 'react-router-dom';

import backIcon from '../../assets/images/icons/back.svg';
import './index.css';

const BackHeader = ({ category }) => (
  <div className="BackHeader">
    <div className="BackHeaderGroup">
      <Link to="/meledy"><img src={backIcon} alt="menu icon" height="35" /></Link>
      <div className="CategoryName">
      <h5>{category}</h5>
      </div>
    </div>
  </div>
)

export default BackHeader;
