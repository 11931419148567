import React from 'react';
import { Link } from 'react-router-dom';

//sytle & component//
import './index.css';
import Header from '../../components/Header';
import TitleSection from'../../components/TitleSection';

//image//
import Number06 from '../../assets/images/numbers/06.svg';
import NextIcon from '../../assets/images/icons/next.svg';

const PoppingPlace = () => (
  <div className="PageContainer page">
    <div className="Canvas-PoppingIntoThePlace">
      <Header mapStage={6}/>
      <TitleSection 
      image={Number06} alt="06"
      title="The Local Inspirations of Pop"
      titleCH="在地音樂能量"
      />
      <div className="Playlist">
        <iframe title="popping-place" src="https://widget.kkbox.com/v1/?id=4m2ZiaueeA8t0fma4c&amp;type=playlist&amp;terr=tw&amp;lang=tc" scrolling="yes"></iframe>
      </div>
      <div className="NextArrow">
        <Link to="/meledy"><img className="next-link-img" src={NextIcon} alt="Go to next stage"/></Link>
      </div>
    </div>
  </div>
)

export default PoppingPlace;
