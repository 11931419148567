import React from 'react';
import { Link } from 'react-router-dom';

// style & component // 
import './index.css';
import Header from '../../components/Header';
import InfoCard from '../../components/InfoCard';

//image //
import NextIcon from '../../assets/images/icons/next.svg';

const Teleport = () => (
  <div className="PageContainer page">
    <div className="Canvas-Teleport">
      <Header mapStage={3}/>
      <div className="Content-Teleport">
        <InfoCard                 
        stageNumber="03"
        stageName="A Stream of Teleportation"
        stageNameCH="樂音流域"
        description="在地南方互動數位地景─
        採集島嶼南端的音樂靈感，幻化作旋律與聲響，參觀展覽的每一個你，都創造了聲音數位地景的一部分"
        />
      </div>
      <div className="PopPopSection">
       <div className="ImageFrame-PopPop"></div>
      </div>
      <div className="NextArrow">
        <Link to="/popping-site-a"><img className="next-link-img" src={NextIcon} alt="Go to next stage"/></Link>
      </div>
    </div>
  </div>
)

export default Teleport;
