import React from 'react';
import QrReader from 'react-qr-reader';

import './index.css';

const QRScanner = ({ updateScanResult }) => {
  const delayTime = 100;
  
  const previewStyle = {
    width: '100%',
  }

  const handleScan = (result) => {
    result && updateScanResult(result);
  }

  const handleError = (err) => {
    console.error(err);
  }

  return(
    <div className="qrReader-wrapper">
      <div>
        <QrReader
          delay={delayTime}
          style={previewStyle}
          facingMode="environment"
          onError={handleError}
          onScan={handleScan}
        />
      </div>
    </div>
  )
}

export default QRScanner;