import Entrance from '../../assets/images/stage/entrance.svg';
import Stage01 from '../../assets/images/stage/stage01.svg';
import Stage02 from '../../assets/images/stage/stage02.svg';
import Stage03 from '../../assets/images/stage/stage03.svg';
import Stage04 from '../../assets/images/stage/stage04.svg';
import Stage05 from '../../assets/images/stage/stage05.svg';
import Stage06 from '../../assets/images/stage/stage06.svg';
import Stage07 from '../../assets/images/stage/stage07.svg';
import Stage08 from '../../assets/images/stage/stage08.svg';

export const floorData = {
  0: {
    stage: 0,
    floor: '4',
    map: Entrance,
    stageEName: 'Entrance',
    stageCName: '入口',
  },
  1: {
    stage: 1,
    floor: '4',
    map: Stage01,
    stageEName: '1. From the Back Stage',
    stageCName: '從後台出發',
  },
  2: {
    stage: 2,
    floor: '4',
    map: Stage02,
    stageEName: '2. The Story of Pop',
    stageCName: '好歌共鳴',
  },
  3: {
    stage: 3,
    floor: '4',
    map: Stage03,
    stageEName: '3. A Stream of Teleportation',
    stageCName: '樂音流域',
  },
  4: {
    stage: 4,
    floor: '4',
    map: Stage04,
    stageEName: '4. Into the Scenes of Pop',
    stageCName: '走入發聲現場',
  },
  5: {
    stage: 5,
    floor: '4',
    map: Stage05,
    stageEName: '5. Into the Images of Pop',
    stageCName: '走入流行影像',
  },
  6: {
    stage: 6,
    floor: '6',
    map: Stage06,
    stageEName: '6. The Local Inspirations of Pop',
    stageCName: '在地音樂能量',
  },
  7: {
    stage: 7,
    floor: '6',
    map: Stage07,
    stageEName: '7. A Melody of Our Island',
    stageCName: '美麗島旋律',
  },
  8: {
    stage: 8,
    floor: '6',
    map: Stage08,
    stageEName: 'Shop',
    stageCName: '商店',
  }
}