import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { useMediaQuery } from 'react-responsive';

import { getZone4a } from '../../api/popmusic';

//style & component//
import 'slick-carousel/slick/slick.css';
import './index.css';

import Header from '../../components/Header';
import TitleSection from'../../components/TitleSection';

//image//
import Number04 from '../../assets/images/numbers/04.svg';
import NextIcon from '../../assets/images/icons/next.svg';
import DiscCube from '../../assets/images/podcastCard/03-album.jpg';
import MixCube from '../../assets/images/podcastCard/01-mix.jpg';
import RecordCube from '../../assets/images/podcastCard/02-record.jpg';

const PoppingSiteA = () => {
  const [zone4aList, setZone4aList] = useState({});
  const isLaptop = useMediaQuery({ minDeviceWidth: 768 });
  const mediumSize = useMediaQuery({ minDeviceWidth: 360, maxDeviceWidth: 414, minDeviceHeight: 622 });
  const [carouselIdx, setCarouselIdx] = useState(0);
  const afterChange = (idx) => {
    setCarouselIdx(idx);
  }
  let centerPadding = '60px';
  if (mediumSize) {
    centerPadding = '70px';
  }
  if (isLaptop) {
    centerPadding = '160px';
  }
  const carouselConfig = {
    className: 'carousel',
    initialSlide: carouselIdx,
    dots: false,
    infinite: true,
    arrows: false,
    centerMode: true,
    centerPadding: centerPadding,
    afterChange
  };
  useEffect(() => {
    getZone4a()
      .then(response => {
        response && setZone4aList(response);
      });
    return () => {
      setZone4aList({});
    };
	}, []);
  return (
    <div className="PageContainer page">
      <div className="Canvas-PoppingIntoTheSite-a">
        <Header mapStage={4}/>
        <TitleSection 
          image={Number04} alt="04"
          title="Into the Scenes of Pop"
          titleCH="走入發聲現場"
        />
        <div className="Content-PoppingIntoTheSite-a">
          <p>收聽不同音樂工作者的發聲現場</p>
          <Slider {...carouselConfig}>
            <img src={DiscCube} className={carouselIdx !== 0 ? 'unselected' : null} alt="DiscCube" />
            <img src={MixCube} className={carouselIdx !== 1 ? 'unselected' : null} alt="MixCube" />
            <img src={RecordCube} className={carouselIdx !== 2 ? 'unselected' : null} alt="RecordCube" />
          </Slider>
        </div>
        <div className="Player">
          {Object.keys(zone4aList).length > 0 && (
            <iframe title="poppingsiteA" src={zone4aList[carouselIdx].songlist[0]} scrolling="no"></iframe>
          )
          }
        </div>
        <div className="NextArrow">
          <Link to="/popping-site-b"><img className="next-link-img" src={NextIcon} alt="Go to next stage"/></Link>
        </div>
      </div>
    </div>
  )
}

export default PoppingSiteA;
