import React from 'react';
import './index.css';

const PodcastCover = props=>(
  <div className="PodcastCoverSection" style={{ backgroundImage: `url(${props.image})` }}>
    <div className="PodcastInfo">
      <p> {props.title} <br/> {props.host}</p>
    </div>
  </div>
)

export default PodcastCover;
