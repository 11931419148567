import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Home from './containers/Home';
import MapPage from './containers/MapPage';
import BehindStage from './containers/BehindStage';
import PopStory from './containers/PopStory';
import Teleport from './containers/Teleport';
import PoppingSiteA from './containers/PoppingSiteA';
import PoppingSiteB from './containers/PoppingSiteB';
import PoppingScene from './containers/PoppingScene';
import PoppingPlace from './containers/PoppingPlace';
import Meledy from './containers/Meledy';

import MenuModal from './components/MenuModal';
import MapModal from './components/MapModal';

import './App.css';

const routes = [
  {
    path: '/home',
    component: Home,
  },
  {
    path: '/map',
    component: MapPage,
  },
  {
    path: '/behind-stage',
    component: BehindStage,
  },
  {
    path: '/pop-story',
    component: PopStory,
  },
  {
    path: '/teleport',
    component: Teleport,
  },
  {
    path: '/popping-site-a',
    component: PoppingSiteA,
  },
  {
    path: '/popping-site-b',
    component: PoppingSiteB,
  },
  {
    path: '/popping-scene',
    component: PoppingScene,
  },
  {
    path: '/popping-place',
    component: PoppingPlace,
  },
  {
    path: '/meledy',
    component: Meledy,
  },
  {
    path: '/menu',
    component: MenuModal
  },
  {
    path: '/site-map',
    component: MapModal
  }
];

const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      render={props => (
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
const Routes = () => {
  const location = useLocation();
  const timeout = { enter: 400, exit: 200 };
  return (
    <TransitionGroup>
      <CSSTransition
          timeout={timeout}
          classNames='page-slider'
          key={location.key}
          mountOnEnter={false}
          unmountOnExit={true}
        >
        <div className='up'>
          <ScrollToTop />
          <Switch location={location}>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </div>
      </CSSTransition>
    </TransitionGroup>
  )
}
const App = () => {
  return (
    <Router>
      <Route exact path="/" render={() => (<Redirect to="/home" />)} />
      <Routes></Routes>
    </Router>
  );
}

export default App;
