import React from 'react';
import { Link } from 'react-router-dom';

//style & components//
import './index.css';

//image//
import NextIcon from '../../assets/images/icons/next.svg';


const IndexPage = () => (
  <div className="PageContainer">
    <div className="Canvas-Home">
      <div className="home-img"></div>
      <div className="NextArrow">
        <Link to="/map"><img className="next-link-img" src={NextIcon} alt="Next"/></Link>
      </div>
    </div>
  </div>
)

export default IndexPage;
