import React from 'react';
import './index.css'

const TitleSection = props => (
  <div className="TitleSection">
    <div className="TitleSectionGroup">
      <div className ="TitleItem"> <img src={props.image} alt=""height="53" /> </div>
      <div className ="TitleItem">
        <div>
          <div>{props.title}</div>
          <div>{props.titleCH}</div>
        </div>
      </div>
    </div>
  </div>
)

export default TitleSection;
