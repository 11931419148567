import React, { useState } from 'react';

//style & component//
import './index.css';
import { floorData } from './floorData';

//image//
import ToBegining from '../../assets/images/player/part/toBegining.svg';
import ToEnd from '../../assets/images/player/part/toEnd.svg';

const floorMap = floorData;

const Map = ({ mapStage }) => {
  const [stage, setStage] = useState(mapStage ?? 0);
  const handlePrevStage = () => {
    const prevStage = stage - 1;
    prevStage === -1 ? setStage(0) : setStage(prevStage);
  }
  const handleNextStage = () => {
    const nextStage = stage + 1;
    nextStage === 9 ? setStage(8) : setStage(nextStage);
  }
  const handleFloorChange = (floor) => {
    setStage(floor);
  }
  return(
    <>
      <div className="FloorButtons">
        <div className="FloorButtonsGroup">
          <div><button onClick={() => handleFloorChange(0)} className={floorMap[stage].floor === '4' ? 'active' : null}>4F</button></div>
          <div><button onClick={() => handleFloorChange(6)} className={floorMap[stage].floor === '6' ? 'active' : null}>6F</button></div>
        </div>
      </div>
      <div className="Content-MapPage">
        <div className="ImageFrame-Map">
          <img src={floorMap[stage].map} alt="Maps" />
        </div>
        
        <div className="MapControlGroup">
          <div className="MapControl">
            <button onClick={handlePrevStage}><img src={ToBegining} alt="Prev"/></button>
            <p>{floorMap[stage].stageEName}<br></br>{floorMap[stage].stageCName}</p>
            <button onClick={handleNextStage}><img src={ToEnd} alt="Next"/></button>
          </div>
            <div className="ProgressBar">
              <div className={`process stage${stage}`}></div>
            </div>
        </div>
      </div>
    </>
  )
};
export default Map;
