import React from 'react';
import { Link } from 'react-router-dom';

import mapTitle from '../../assets/images/mapTitle.svg';
import menuIcon from '../../assets/images/icons/menu.svg';
import './index.css';

const FirstHeader = ({ siteTitle }) => {
  return(
    <>
      <div className="FirstHeader">
        <div className="FirstHeaderGroup">
          <Link to="/home"><img src={mapTitle} alt="map title" height="35" /></Link>
          <Link to="/menu"><img src={menuIcon} alt="menu" height="35" /></Link>
        </div>
      </div>
    </>
  )
}

export default FirstHeader;
