import Bar from '../../assets/images/topic/bar.svg';
import Nightlife from '../../assets/images/topic/nightlife.svg';
import South from '../../assets/images/topic/south.svg';

export const meledyData = [
  {
    cate: 'bar50',
    icon: Bar,
    label: '五零年代酒吧街',
    url: <iframe title="category" src="https://widget.kkbox.com/v1/?id=5YaC0rm93dAIMGAfyH&amp;type=playlist&amp;terr=tw&amp;lang=tc" scrolling="yes"></iframe>,
  },
  {
    cate: 'nightlife',
    icon: Nightlife,
    label: '五光十色夜生活',
    url: <iframe title="category" src="https://widget.kkbox.com/v1/?id=PXUZXixOT1DROOncws&amp;type=playlist&amp;terr=tw&amp;lang=tc" scrolling="yes"></iframe>,
  },
  {
    cate: 'south',
    icon: South,
    label: '南方而來音樂祭',
    url: <iframe title="category" src="https://widget.kkbox.com/v1/?id=SmSXJ1CaNg04WxfAV_&amp;type=playlist&amp;terr=tw&amp;lang=tc" scrolling="yes"></iframe>,
  },
]