import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import './index.css'

import closeIcon from '../../assets/images/icons/close.svg'

const MenuModal = ({ close }) => {
  const history = useHistory();
  return (
    <div className="ModalContainer">
      <div className="MenuModal">
        <div className="MenuModalHeader">
          <div className="EmptySection"></div>
          <button onClick={() => history.goBack()}><img src={closeIcon} alt="close" height="35" /></button>
        </div>
        <div className="StageLinksGroup">
          <Link to="/behind-stage"><div className="StageLink">
          <h1>01</h1><h5>From the Back Stage<br></br>從後台出發</h5>
          </div></Link>
          <Link to="/pop-story"><div className="StageLink">
          <h1>02</h1><h5>The Story of Pop<br></br>好歌共鳴</h5>
          </div></Link>
          <Link to="/teleport"><div className="StageLink">
          <h1>03</h1><h5>A Stream of Teleportation<br></br>樂音流域</h5>
          </div></Link>
          <Link to="/popping-site-a"><div className="StageLink">
          <h1>04</h1><h5>Into the Scenes of Pop<br></br>走入發聲現場</h5>
          </div></Link>
          <Link to="/popping-scene"><div className="StageLink">
          <h1>05</h1><h5>Into the Images of Pop<br></br>走入流行影像</h5>
          </div></Link>
          <Link to="/popping-place"><div className="StageLink">
          <h1>06</h1><h5>The Local Inspirations of Pop<br></br>在地音樂能量</h5>
          </div></Link>
          <Link to="/meledy"><div className="StageLink">
          <h1>07</h1><h5>A Melody of Our Island<br></br>美麗島旋律</h5>
          </div></Link>
        </div>    
      </div>
    </div>
  )
}
  
  export default MenuModal;
