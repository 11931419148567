import React, { useState, useEffect } from 'react';

import { getZone7 } from '../../api/popmusic';

//style & component//
import 'slick-carousel/slick/slick.css';
import './index.css';
import Header from '../../components/Header';
import TitleSection from'../../components/TitleSection';
import BackHeader from '../../components/BackHeader';
import { meledyData } from './meledyData';

//image//
import Number07 from '../../assets/images/numbers/07.svg';

const meledyObj = meledyData;

const Meledy = () => {
  const [category, setCategory] = useState('home');
  const [zone7List, setZone7List] = useState({});
  useEffect(() => {
    getZone7()
      .then(response => {
        response && setZone7List(response);
      });
    return () => {
      setZone7List({});
    };
	}, []);
  const handleCateChange = (cate) => {
    setCategory(cate);
  }
  console.log(zone7List);
  const MeledyDetail = ({ category }) => {
    const meledyDetail = zone7List && zone7List.find(o => o.label === category);
    const urlComp = meledyObj.find(o => o.cate === category);
    return (
      <>
        {meledyDetail && (
          <div className="PageContainer">
            <div className="Canvas-OurMeledy-Category">
              <BackHeader category={meledyDetail.cate} />
              <div className="Playlist">
                {urlComp.url}
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
  const MeledyCategory = () => {
    return (
      <div className="PageContainer page">
        <div className="Canvas-OurMeledy">
          <Header mapStage={7}/>
          <TitleSection 
          image={Number07} alt="07"
          title="A Melody of Our Island"
          titleCH="美麗島旋律"
          />
          <div className="Content-OurMeledy">
            <p>打開手機裡的故事歌單<br></br>從音樂記憶資料庫裡找到你跟高雄的共同回憶吧！</p>
            <div className="AllCategories">
              <div className='row'>
                <div className='column'>
                  <div className="Category" onClick={() => handleCateChange(meledyData[0].cate)}>
                    <img src={meledyData[0].icon} alt="" />
                    <h5>{meledyData[0].label}</h5>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='column'>
                  <div className="Category" onClick={() => handleCateChange(meledyData[1].cate)}>
                    <img src={meledyData[1].icon} alt="" />
                    <h5>{meledyData[1].label}</h5>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='column'>
                  <div className="Category" onClick={() => handleCateChange(meledyData[2].cate)}>
                    <img src={meledyData[2].icon} alt="" />
                    <h5>{meledyData[2].label}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      {category === 'home' ? <MeledyCategory /> : <MeledyDetail category={category} />}
    </>
  )
}

export default Meledy;
