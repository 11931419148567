import React from 'react';
import { Link } from 'react-router-dom';

//style & component//
import './index.css'
import Header from '../../components/Header';
import TitleSection from'../../components/TitleSection';
import PodcastCover from '../../components/PodcastCover';

//image//
import Number05 from '../../assets/images/numbers/05.svg';
import FireEX from '../../assets/images/fireEX.jpg';
import NextIcon from '../../assets/images/icons/next.svg';

const PoppingScene = () => {
  return (
    <div className="PageContainer page">
      <div className="Canvas-PoppingIntoTheScene">
        <Header mapStage={5}/>
        <TitleSection 
          image={Number05} alt="05"
          title="Into the Images of Pop"
          titleCH="走入流行影像"
        />
        <div className="Content-PoppingIntoTheScene">
          <div>
            <div>收聽互動體驗完整音樂-滅火器「南國的風」</div>
            <div>(圖片授權：火氣音樂)</div>
          </div>
          <PodcastCover image={FireEX} />
        </div>
        <div className="Player">
          <iframe title="popping-scene" src="https://podcast.kkbox.com/embed/episode/Kk9GmZ0jA0QQOoGcfQ?lang=tc" width="100%" height="176" frameBorder="0"></iframe>
        </div>
        <div className="NextArrow">
          <Link to="/popping-place"><img className="next-link-img" src={NextIcon} alt="Go to next stage"/></Link>
        </div>
      </div>
    </div>
  )
}

export default PoppingScene;
