const API = 'https://guide.kmc-exhibition.tw/api';

export const getZone1 = async() => {
  const url = `${API}/getzone1`;
  try {
    const result = await fetch(url, {
      method: 'GET',
    });
    const message = await result.json();

    return message.msg;
  } catch (error) {
    throw new Error('Get Zone1 list failed');
  }
};

export const getQRcode = async({ parameter }) => {
  const url = `${API}/checkqrcode?qrcode=${encodeURIComponent(parameter)}`;
  try {
    const result = await fetch(url, {
      method: 'GET',
    });
    const message = await result.json();

    return message.msg;
  } catch (error) {
    throw new Error('Get QRcode failed');
  }
};

export const getZone4a = async() => {
  const url = `${API}/getzone4a`;
  try {
    const result = await fetch(url, {
      method: 'GET',
    });
    const message = await result.json();

    return message.msg;
  } catch (error) {
    throw new Error('Get Zone4a list failed');
  }
};

export const getZone4b = async() => {
  const url = `${API}/getzone4b`;
  try {
    const result = await fetch(url, {
      method: 'GET',
    });
    const message = await result.json();

    return message.msg;
  } catch (error) {
    throw new Error('Get Zone4b list failed');
  }
};

export const getZone5 = async() => {
  const url = `${API}/getzone5`;
  try {
    const result = await fetch(url, {
      method: 'GET',
    });
    const message = await result.json();

    return message.msg;
  } catch (error) {
    throw new Error('Get Zone5 list failed');
  }
};
export const getZone7 = async() => {
  const url = `${API}/getzone7`;
  try {
    const result = await fetch(url, {
      method: 'GET',
    });
    const message = await result.json();

    return message.msg;
  } catch (error) {
    throw new Error('Get Zone7 list failed');
  }
};