import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';

import { getZone4b } from '../../api/popmusic';

//style & component//
import 'slick-carousel/slick/slick.css';
import './index.css';

import Header from '../../components/Header';
import TitleSection from'../../components/TitleSection';

//image//
import Number04 from '../../assets/images/numbers/04.svg';
import NextIcon from '../../assets/images/icons/next.svg';
import Shellac from '../../assets/images/eraCard/shellac.svg';
import Vinyl from '../../assets/images/eraCard/vinyl.svg';
import Audio from '../../assets/images/eraCard/audio.svg';
import Tape from '../../assets/images/eraCard/tape.svg';
import Karaoke from '../../assets/images/eraCard/karaoke.svg';
import CD from '../../assets/images/eraCard/cd.svg';
import Mp3 from '../../assets/images/eraCard/mp3.svg';
import Stream from '../../assets/images/eraCard/stream.svg';

const PoppingSiteB = () => {
  const [zone4bList, setZone4bList] = useState({});
  const isLaptop = useMediaQuery({ minDeviceWidth: 768 });
  const mediumSize = useMediaQuery({ minDeviceWidth: 360, maxDeviceWidth: 414, minDeviceHeight: 622 });
  let centerPadding = '80px';
  if (mediumSize) {
    centerPadding = '90px';
  }
  if (isLaptop) {
    centerPadding = '160px';
  }
  const [carouselIdx, setCarouselIdx] = useState(0);
  const afterChange = (idx) => {
    setCarouselIdx(idx);
  }
  const carouselConfig = {
    className: 'carousel',
    initialSlide: carouselIdx,
    dots: false,
    infinite: true,
    arrows: false,
    centerMode: true,
    centerPadding: centerPadding,
    afterChange
  };
  useEffect(() => {
    getZone4b()
      .then(response => {
        response && setZone4bList(response);
      });
    return () => {
      setZone4bList({});
    };
	}, []);
  return (
    <div className="PageContainer page">
      <div className="Canvas-PoppingIntoTheSite-b">
        <Header mapStage={4}/>
        <TitleSection 
          image={Number04} alt="04"
          title="Into the Scenes of Pop"
          titleCH="走入發聲現場"
        />
        <div className="Content-PoppingIntoTheSite-b">
          <p>按下播放鍵，穿梭不同年代，收聽當時的流行歌曲</p>
          <Slider {...carouselConfig}>
            <div className={carouselIdx !== 0 ? 'slick-item-b unselected' : 'slick-item-b'}>
              <img src={Shellac} alt="Shellac" />
              <p>蟲膠唱片年代</p>
            </div>
            <div className={carouselIdx !== 1 ? 'slick-item-b unselected' : 'slick-item-b'}>
              <img src={Vinyl} alt="Vinyl" />
              <p>黑膠唱片年代</p>
            </div>
            <div className={carouselIdx !== 2 ? 'slick-item-b unselected' : 'slick-item-b'}>
              <img src={Audio} alt="Audio" />
              <p>組合音響年代</p>
            </div>
            <div className={carouselIdx !== 3 ? 'slick-item-b unselected' : 'slick-item-b'}>
              <img src={Tape} alt="Tape" />
              <p>錄音帶年代</p>
            </div>
            <div className={carouselIdx !== 4 ? 'slick-item-b unselected' : 'slick-item-b'}>
              <img src={Karaoke} alt="Karaoke" />
              <p>卡拉OK伴唱帶年代</p>
            </div>
            <div className={carouselIdx !== 5 ? 'slick-item-b unselected' : 'slick-item-b'}>
              <img src={CD} alt="CD" />
              <p>CD年代</p>
            </div>
            <div className={carouselIdx !== 6 ? 'slick-item-b unselected' : 'slick-item-b'}>
              <img src={Mp3} alt="Mp3" />
              <p>MP3年代</p>
            </div>
            <div className={carouselIdx !== 7 ? 'slick-item-b unselected' : 'slick-item-b'}>
              <img src={Stream} alt="Stream" />
              <p>線上串流音樂年代</p>
            </div>
          </Slider>
        </div>
        <div className="Player">
          {Object.keys(zone4bList).length > 0 && (
            <iframe title="poppingsiteB" src={zone4bList[carouselIdx].songlist[0]} scrolling="no"></iframe>
          )
          }
        </div>
        <div className="NextArrow">
          <Link to="/popping-scene"><img className="next-link-img" src={NextIcon} alt="Go to next stage"/></Link>
        </div>
      </div>
    </div>
  )
}

export default PoppingSiteB;
