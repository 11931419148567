import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Map from '../Map';

import './index.css'
import mapTitle from '../../assets/images/mapTitle.svg';
import closeIcon from '../../assets/images/icons/close.svg'

const MapModal = () => {
  const history = useHistory();
  const { state } = useLocation();
  return (
    <div className="ModalContainer MapModalContainer">
      <div className="MenuModal">
        <div className="MenuModalHeader">
          <div><img src={mapTitle} alt="map title" height="35" /></div>
          <button onClick={() => history.goBack()}><img src={closeIcon} alt="close" height="35" /></button>
        </div>
        <Map mapStage={state.stage} />
      </div>
    </div>
  )
}
  
  export default MapModal;
