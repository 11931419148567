import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo.svg';
import mapIcon from '../../assets/images/icons/map.svg';
import menuIcon from '../../assets/images/icons/menu.svg';
import './index.css';

const Header = ({ mapStage }) => {
  return (
    <>
      <div className="Header">
        <div className="HeaderGroup">
          <Link to="/home"><img src={logo} alt="logo" height="35" /></Link>
          <Link to={{pathname: '/site-map', state: {stage: mapStage}}}><img src={mapIcon} alt="map icon" height="35" /></Link>
          <Link to="/menu"><img src={menuIcon} alt="menu icon" height="35" /></Link>
        </div>
      </div>
    </>
  )
}

export default Header;
