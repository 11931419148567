import React from 'react';
import { Link } from 'react-router-dom';

//style & component//
import './index.css';
import FirstHeader from '../../components/FirstHeader';
import Map from '../../components/Map';

import StartButtonImg from '../../assets/images/startButton.svg';

const MapPage = ({ data }) => {
  return(
    <div className="PageContainer page">
      <div className="Canvas-MapPage">
        <FirstHeader />
        <Map />
        <div className="StartBtn">
          <Link to="/behind-stage"><img src={StartButtonImg} alt="Start the exhibition" height="42"/></Link>
        </div>
      </div>
    </div>
  )
};
export default MapPage;
