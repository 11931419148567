import React from 'react';
import { Link } from 'react-router-dom';

import './index.css';
import Header from '../../components/Header';
import InfoCard from '../../components/InfoCard';
import NextIcon from '../../assets/images/icons/next.svg';

const BehindStage = () => {
  return (
    <div className="PageContainer page">
      <div className="Canvas-BehindTheStage">
        <Header mapStage={1}/>
        <div className="Content-BehindTheStage">
          <InfoCard 
            stageNumber="01"
            stageName="From the Back Stage"
            stageNameCH="從後台出發"
            description="Start from here, start to hear. 感受發光發熱的前一刻，一起從後台出發探索流行音樂"
          />
        </div>
        <div className="Player">
          <iframe title="behind" src="https://podcast.kkbox.com/embed/episode/Ot2ZxBDSu6AHn00lxI?lang=tc&amp;theme=dark" scrolling="no"></iframe>
        </div>
        <div className="NextArrow">
          <Link to="/pop-story"><img className="next-link-img" src={NextIcon} alt="Go to next page"/></Link>
          </div>
      </div>
    </div>
  )
};

export default BehindStage;
